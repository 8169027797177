export class ProcessURL {
    isFormatSupported(format, dataUri) {
        return new Promise((resolve, reject) => {
            const image = new Image();

            image.src = `data:image/${format};base64,${dataUri}`;

            image.onload = () => {
                resolve(true);
            };

            image.onerror = () => {
                resolve(false);
            };
        });
    }

    async initProcessURL(config) {
        const supportsAvif = await this.isFormatSupported(
            'avif',
            'AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A='
        );
        const supportsWebP = await this.isFormatSupported(
            'webp',
            'UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA'
        );
        const newFormat = supportsAvif || supportsWebP;
        const urlHttp = `/fstrz/r/${newFormat ? 'c/' : ''}`;
        const urlHttps = `/fstrz/r/s/${newFormat ? 'c/' : ''}`;
        config.processURL = (options) => {
            const https = options.url.includes('https://');
            //options.url is automatically rebuild by cloudimage before processURL is called if the url is absolute
            //if url containns urlHttp or urlHttps, it means that the url is already processed by engine
            if (!options.url.includes(urlHttp) && !options.url.includes(urlHttps)) {
                //if engine is not used, we need to add the token and domain to the url
                if (!config.engine) {
                    options.url = options.url
                        .replace('https://', `https://${options.token}.${options.domain}${urlHttps}`)
                        .replace('http://', `http://${options.token}.${options.domain}${urlHttp}`);
                } else if (options.url.includes('/fstrz/r/s/')) {
                    //if engine is used, we need correct the url with new format and protocol
                    options.url = options.url
                        .replace('/fstrz/r/s/', https ? urlHttps : urlHttp);
                } else if (options.url.includes('/fstrz/r/')) {
                    //if engine is used, we need correct the url with new format and protocol
                    options.url = options.url
                        .replace('/fstrz/r/', https ? urlHttps : urlHttp);
                }else if (!options.url.includes('/fstrz/r/c/' || '/fstrz/r/s/c/')) {
                    //if engine is used but the url is not processed by engine, we need to add the domain to the url
                    options.url = options.url
                        .replace('https://', `https://${options.domain}${urlHttps}`)
                        .replace('http://', `http://${options.domain}${urlHttp}`);
                }

                //if new format is supported, we need to add the format to the url if it is not already there
                if (newFormat && !options.url.includes('.avif') && !options.url.includes('.webp')) {
                    options.url = options.url.replace('?', `${supportsAvif ? '.avif?' : '.webp?'}`);
                }
            }
            return options.url;
        };
        config.processQueryString = function (params) {
            return [
                params.widthQ ? `frz-w=${params.widthQ}` : '',
                params.heightQ && !params.processOnlyWidth ? `${params.widthQ ? '&' : ''}frz-h=${params.heightQ}` : '',
                params.restParamsQ ? `&${params.restParamsQ}` : '',
            ].join('');
        };
    }
}

export const processUrl = new ProcessURL();
